import { OutlinedInputProps, TextField, TextFieldProps } from '@mui/material'
import React, { ChangeEvent, useState, useEffect } from 'react'
import {
  NumericFormat,
  PatternFormat,
  PatternFormatProps,
  InputAttributes,
  NumericFormatProps,
  usePatternFormat,
} from 'react-number-format'

type CustomMaskProps = {
  onChange: (event: { target: { value: string | number | undefined } }) => void
  onBlur: () => void
  value: string | number
  allowLeadingZeros?: boolean
}

const PhoneFormat = React.forwardRef<
  PatternFormatProps<InputAttributes>,
  CustomMaskProps
>((props, ref) => {
  const { onChange, value, ...other } = props
  const { removeFormatting } = usePatternFormat({ format: '(###) ###-####' })

  const cleanedValue = value || ''

  return (
    <PatternFormat
      {...other}
      value={removeFormatting(cleanedValue as string)}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({ target: { value: values.formattedValue as string } })
      }}
      format="(###) ###-####"
      valueIsNumericString
    />
  )
})

const DollarsFormat = React.forwardRef<
  NumericFormatProps<InputAttributes>,
  CustomMaskProps
>((props, ref) => {
  const { onChange, ...other } = props

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({ target: { value: values.floatValue } })
      }}
      thousandSeparator
      decimalScale={0}
      prefix="$"
      valueIsNumericString
    />
  )
})

const DollarsAndCentsFormat = React.forwardRef<
  NumericFormatProps<InputAttributes>,
  CustomMaskProps
>((props, ref) => {
  const { onChange, ...other } = props

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({ target: { value: values.floatValue } })
      }}
      thousandSeparator
      fixedDecimalScale
      decimalScale={2}
      prefix="$"
      valueIsNumericString
    />
  )
})

const NumberFormat = React.forwardRef<
  NumericFormatProps<InputAttributes>,
  CustomMaskProps
>((props, ref) => {
  const { onChange, allowLeadingZeros, ...other } = props
  return (
    <NumericFormat
      allowNegative={false}
      thousandSeparator
      allowLeadingZeros={allowLeadingZeros}
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            value: allowLeadingZeros ? values.value : values.floatValue,
          },
        })
      }}
      valueIsNumericString
    />
  )
})

const PercentFormat = React.forwardRef<
  NumericFormatProps<InputAttributes>,
  CustomMaskProps
>((props, ref) => {
  const { onChange, ...other } = props
  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      suffix="%"
      onValueChange={(values) => {
        onChange({ target: { value: values.floatValue } })
      }}
    />
  )
})

const DecimalPercentFormat = React.forwardRef<
  NumericFormatProps<InputAttributes>,
  CustomMaskProps
>((props, ref) => {
  const { onChange, value, ...other } = props

  return (
    <NumericFormat
      {...other}
      value={(value as number) * 100}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({ target: { value: (values.floatValue || 0) / 100 } })
      }}
      decimalScale={2}
      suffix="%"
    />
  )
})

const YearFormat = React.forwardRef<
  NumericFormatProps<InputAttributes>,
  CustomMaskProps
>((props, ref) => {
  const { onChange, ...other } = props

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({ target: { value: values.floatValue } })
      }}
      valueIsNumericString
      maxLength={4}
    />
  )
})
const SSNFormat = React.forwardRef<
  PatternFormatProps<InputAttributes>,
  CustomMaskProps
>((props, ref) => {
  const { onChange, value, onBlur, ...other } = props
  const { removeFormatting } = usePatternFormat({ format: '###-##-####' })
  const [showPassword, setShowPassword] = useState(true)

  const cleanedValue = value || ''

  return (
    <PatternFormat
      {...other}
      value={removeFormatting(cleanedValue as string)}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({ target: { value: values.formattedValue as string } })
      }}
      format="###-##-####"
      valueIsNumericString
      type={showPassword ? 'text' : 'password'}
      onBlur={() => {
        onBlur()
        setShowPassword(false)
      }}
      onFocus={() => {
        setShowPassword(true)
      }}
    />
  )
})

const EINFormat = React.forwardRef<
  PatternFormatProps<InputAttributes>,
  CustomMaskProps
>((props, ref) => {
  const { onChange, value, onBlur, ...other } = props
  const { removeFormatting } = usePatternFormat({ format: '##-#######' })
  const [showPassword, setShowPassword] = useState(true)

  const cleanedValue = value || ''

  return (
    <PatternFormat
      {...other}
      value={removeFormatting(cleanedValue as string)}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({ target: { value: values.formattedValue as string } })
      }}
      format="##-#######"
      valueIsNumericString
      type={showPassword ? 'text' : 'password'}
      onBlur={() => {
        onBlur()
        setShowPassword(false)
      }}
      onFocus={() => {
        setShowPassword(true)
      }}
    />
  )
})

export type FormattedTextFieldProps = TextFieldProps & {
  format?:
    | 'text'
    | 'dollars'
    | 'dollarsAndCents'
    | 'phone'
    | 'number'
    | 'year'
    | 'email'
    | 'fullName'
    | 'percent'
    | 'decimalPercent'
    | 'cityState'
    | 'ssn'
    | 'ein'

  formatProps?: NumericFormatProps | PatternFormatProps
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
  value: string | number | undefined
  variant?: 'standard' | 'inline' | 'outlined' | 'filled'
  pattern?: RegExp
  'data-testid'?: string
  centered?: boolean
  align?: string
}

const FormattedTextField = (props: FormattedTextFieldProps) => {
  const {
    format,
    onChange,
    value,
    InputProps,
    centered,
    formatProps,
    align,
    'data-testid': dataTestId,
    ...otherProps
  } = props

  // seems like there is a bug when resetting form through react-hook-form
  // where value will reset but displayed value will not change.
  // likely because resetting doesn't propagate to call onChange for the textfields.
  useEffect(() => {
    if (value === null || value === undefined) {
      const input = document.querySelector(`input[name="${otherProps.name}"]`)
      if (input) {
        // if there is something in the input value.
        if ((input as HTMLInputElement).value) {
          onChange('' as any)
        }
      }
    }
    // }
  }, [value, onChange, otherProps.name])

  // Dollars, dollars and cents, and number are converted to numbers
  // Phone is preserved as a string
  let inputComponent: any
  switch (format) {
    case 'dollars':
      inputComponent = DollarsFormat
      break
    case 'dollarsAndCents':
      inputComponent = DollarsAndCentsFormat
      break
    case 'number':
      inputComponent = NumberFormat
      break
    case 'year':
      inputComponent = YearFormat
      break
    case 'phone':
      inputComponent = PhoneFormat
      break
    case 'percent':
      inputComponent = PercentFormat
      break
    case 'decimalPercent':
      inputComponent = DecimalPercentFormat
      break
    case 'ssn':
      inputComponent = SSNFormat
      break
    case 'ein':
      inputComponent = EINFormat
      break
    default:
      break
  }
  return (
    <TextField
      value={value}
      InputLabelProps={{
        shrink: true,
      }}
      {...otherProps}
      InputProps={
        {
          ...InputProps,
          inputComponent,
          notched: false,
          inputProps: {
            ...formatProps,
            'data-testid': dataTestId,
            'data-type': 'text-field',
            'data-textformat': format,
            style: {
              textAlign: align || (centered ? 'center' : 'left'),
            },
          },
        } as OutlinedInputProps
      }
      onChange={onChange}
    />
  )
}

export default FormattedTextField

FormattedTextField.defaultProps = {
  format: 'text',
  align: undefined,
  centered: false,
}
